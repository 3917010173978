.chat_c_wrapper{
    /* background-color: aqua; */
    height: 93%;
    margin-top: 10%;
    position: relative;
}
.input_space{
    width: 100%;
    position: absolute;
    bottom: 0;
}
.message_wrapper{
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.chat_conversation_user{
    display: flex;
    flex-direction: column;
    text-align: start;
    background-color: #00ff002a;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    margin-left: 2px;
    width: 75%;
    float: left;
}
.chat_conversation_service{
    display: flex;
    flex-direction: column;
    text-align: end;
    background-color: #00c3ff34;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    margin-right: 2px;
    width: 75%;
    float: right;
}
.chat_conversation_name{
    font-size: 13px;
    color: #000000;
    font-weight: 600;
}
.chat_conversation_message{
    font-size: 14px;
    color: #000000;
    font-weight: 400;
}
.input_space{
    display: flex;
}
.input_space > input{
    width: 78%;
    margin-right: 5px;
    height: 10%;
    border: none;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.input_space> button{
    cursor: pointer;
    width: 20%;
    height: 10%;
    border: none;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    background-color: #00afe4;
    color: #ffffff;
}