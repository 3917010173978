/* 'Montserrat' */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Inter */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Lato */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
/* Oswald */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

.root {
    text-align: center;
    background-color: #110f0a;
  }
  body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .bg-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 600px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .mainboxes {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  .mainboxes > div {
    margin: 15px 0;
    width: 33.33%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .box-one {
    /* background-color: #db750f48; */
  }
  .box-two {
    /* background-color: #0fdb6b48; */
  }
  .box-three {
    /* background-color: #670fdb48; */
  }
  .bonus-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .btn-lp {
    cursor: pointer;
    border-radius: 9999999px;
    width: 100%;
    max-width: 350px;
    min-width: 150px;
    font-weight: bold;
    outline: none;
    padding: 0.6em;
    font-size: 1em;
    letter-spacing: 1px;
  }
  .dinamic-stuff {
    width: 100%;
    padding-left: 15px;
    padding-top: 20px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }
  .settings-list {
    max-width: 800px;
    max-height: 350px;
    background-color: #5a5a5a;
    display: flex;
    flex-direction: column;
  }
  
  .Header-Component {
    padding-top: 10px;
    width: 100%;
    height: 80px;
    align-items: center;
  }
  .logo {
    cursor: pointer;
    margin-left: 25px;
    width: 100%;
    max-width: 350px;
    height: 80px;
    display: flex;
    align-items: center;
  }
  .logo > img {
    width: 20vw;
    max-width: 200px;
    min-width: 150px;
  }
  .info {
    margin-right: 50px;
    height: 80px;
    display: flex;
    align-items: center;
  }
  .list-item-text {
    border: 1px solid #000000;
    margin: 5px;
  }
  
  .info > span {
    cursor: pointer;
    width: max-content;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-size: 0.625em;
    font-weight: bold;
    padding: 0.5em 2em;
    background: transparent;
    outline: none;
    border-radius: 500px;
    border: 1px solid #ffb701;
    color: #ffb701;
    text-transform: uppercase;
    text-decoration: none;
    min-width: min-content;
    transition: 0.2s ease-in-out;
  }
  .info > span:hover {
    transition: 0.2s ease-in-out;
    color: #fceecc;
    border: 1px solid #fceecc;
  }



  .modal-btn {
    cursor: pointer;
    background: #ffffff;
    border-radius: 9999999px;
    width: 40px;
    height: 40px;
    border: none;
    font-weight: bold;
    outline: none;
    padding: 0.35em;
    font-size: 1em;
    margin-top: 15px;
    margin-right: 30px;
    transition: 0.2s ease-in-out;
  }
  .modal-btn:hover {
    transition: 0.2s ease-in-out;
    transform: scale(1.1);
  }


  
  @-webkit-keyframes check {
    100% {
      stroke-dashoffset: 4.5px;
    }
  }
  
  @keyframes check {
    100% {
      stroke-dashoffset: 4.5px;
    }
  }
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }
  
  * {
    box-sizing: inherit;
  }
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  @media only screen and (max-width: 800px) {
    .mainboxes {
      flex-direction: column;
    }
    .mainboxes > div {
      width: 100%;
      height: max-content;
      min-height: 5px;
    }
  }
  @media only screen and (max-height: 600px) {
    .mainboxes > div {
      margin: 15px 0;
      width: 30%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  