.jackpot.name{
    color: #fd2115;
}
.search_icon{
    @include recolor(#0cccce);
}
.search_icon:hover{
    @include recolor(#fd2115);
}
.bonus_wrapper{
    justify-content: flex-end;
    width: 100vw;
    min-height: 380px;
    height: 40vh;
    box-shadow: rgb(32 5 39) 0px -57px 14px -43px inset;
    .bonus_container{
        .bonus_pic{
            align-items: flex-end;
        }
        margin-right:150px ;
        img{
            height: 200% !important;
        }
    }
}
.bonus_slider {
    //margin-top: 0;
    height: 42vh;
    min-height: 350px;

}
.carousel-initialized{
    padding: 0 !important;
}
.header-logo {
    width: initial;
}
.btn.bonus {
  margin-bottom: -80px;
}
@media screen and (max-width: 700px) {
    .btn.bonus {
        right: auto;
        bottom: 30px;
        top: auto;
        height: initial;
    }
    .bonus_wrapper{
        justify-content: center;
        .bonus_container{
            margin-right:0;
        }

    }
}